export const environment = {
  production: false,
  serverPath: 'http://webdev02.rxresourcesolutions.com/',
  appName:"RxResource Solutions",
  appConfig: {
    apiServer: {
      cossa: 'http://localhost:9991/api/',
         }
  },
  clinicalPharmMultiFacilityId: "Multi",
  appcode: "Portal",
  clinicalPharmName: "clinical pharmacology",
  facilityEntityTypeName: "Facility",
  metricsAppCode: "OPERA2",
  metricsAppId: 160
};
