export const DisplayMessage = {
  alreadyExists : (str: string) => `This ${str} already exists in our site.`,
  successMessage: 'The record has been saved.',
  failureMessage: 'Unknown Error.',
  deleteMessage: 'Record has been removed successfully.',
  noDataMessage: (str: string) => `Can't get the ${str} details.`,
  deleteFailureMessage: (str: string) => `Can't delete the ${str} details.`,
  updateFailureMessage: (str: string) => `Can't update the ${str} details.`,
  createFailureMessage: (str: string) => `Can't create the ${str} details.`,
  failureEmailMessage: (str: string) => `Can't send the ${str} email.`,
  successEmailMessage: (str: string) => `Send the ${str} email.`,
  exportFailureMessage: (str: string) => `Can't export the ${str} details.`,
  deleteExceptionMessage: (str: string, message: string) => `Can't delete the ${str} details. Hence ${message}`,
  createExceptionMessage: (str: string, message: string) => `Can't create the ${str} details. Hence ${message}`,
  confirmDeleteMessage: 'Do you want to delete?',
  selectAtleastOne : (str: string) => `Please select at least one  ${str}.`,
  selectAtleastOneSecurityGroup : (str: string) => `At least one ${str} should be assigned to User.`,
  terminationSuccessMessage: 'Access has been removed successfully in user record.',
  removeAccessMessage:(userName:string,orgnizationName:string)=> `Are you sure you want to remove access for ` + userName +` for `+ orgnizationName+`? This will prohibit the user from accessing any Optimizer applications.`,
  updateTerminationFailureMessage: `Something wrong. Can't update the Termination details.`,
  updateFacilityFailureMessage: `Something wrong. Can't update the Facility Overview details.`,
  emailRequired: `Email is required to terminate the user`,
  updateMessage: 'Record has been updated successfully.',
  duplicateRecordMessage: 'The record already exists.',
  domainExistMessage: (str: string) => `The Domain name exists in ${str} organization.`,
  restrictedDomainMessage: (str: string) => `The ${str} domain is not allowed. Please try with other domain.`,
  terminationDateWarningMessage: 'Termination date should not be greater than today’s date.',
  domainWarningMessage: 'Are you sure want to remove the domain from Allowed Domain list.',
  facilityCancelWarningMessage: 'Are you sure you want to discard unsaved data?',
  contractValidationWarningMessage: 'One or more required values is missing or incomplete.',
  assignedFacilityWarningMessage: 'You cannot change the Organization on a contract that has assigned facilities. To change the Organization, remove all assigned facilities first.',
  organizationFacilityWarningMessage: 'You must select an Organization on the Overview tab before assigning facilities.',
  userMobileWarningMessage: 'Please enter valid phone number.',
  appRoleAlreadyExistsMessage: 'App Role Name already exist',
  noDomainMessage: (str: string) => `Domain not found. Please add domain for ${str} Organization.`,
  securityGroupNameWarningMessage: 'You must select a Security Group Name',
  removeUserAccessMessage: 'User access has been removed successfully.',
  clearUserCacheMessage: 'User cache has been cleared successfully.',
  serverErrorMessage: 'Internal server error.',
  facilityLOBAlreadyExistsMessage: 'The facility Line of Business already exists.',
  invalidDEANumberMessage: 'Please enter valid DEA Number. Format should be 9 characters long (2 alpha followed by 7 numerical).',
};

