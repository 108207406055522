export const environment = {
  name: "release",
  production: true,
  appInsights: {
    instrumentationKey: "#{{APPINSIGHTS_INSTRUMENTATION_KEY}}#",
    roleName: "AppSecMgmt.UI",
  },
  serverPath: "#{{SERVER_PATH}}#",
  azureEnv: '#{{azureEnv}}#',
  appCode:"AppSecMgmt",
  appcode:"AppSecMgmt", //// Need for AppMessaging and Help
  appName:"RxAdmin",
  storageEncryptionKey: "123456$#@$^@1ERFj39s4k8fs3h42se1",
  showMenuIcons: true,
  useLocalStorage: false,
  browserCacheExpiryInhours: "#{{Brower_Cache_Expiry_In_Hour}}#",
  b2cConfig:{
    tenant: "#{{B2C_TENANT_ID}}#", // B2C tenant ID - Same for all apps
    applicationId: "#{{B2C_APP_CLIENT_ID}}#", // B2C UI client application Id - changes per app
    signUpSignInPolicy: "B2C_1A_UA_signup_signin", // Identity Experience Custom Policy Name - Same for all apps
    b2cScopes: ["#{{B2C_API_SCOPE}}#"], // taken from the B2C UI app Expose an API  - changes per app
    authorityBaseUrl: "#{{B2C_TENANT_LOGIN_AUTHORITY}}#",  // B2C Tenant Authority - Same for all apps
    redirectUri: '#{{B2C_MSAL_CONFIG_REDIRECT_URI}}#',                      // - changes per app
    postLogoutRedirectUri: "#{{B2C_MSAL_CONFIG_POST_LOGOUT_REDIRECT_URI}}#",    // - changes per app
    forgotPasswordLink: `https://#{{B2C_TENANT_NAME}}#.b2clogin.com/#{{B2C_TENANT_ID}}#/B2C_1A_UA_PASSWORDRESET/oauth2/v2.0/authorize?client_id=#{{B2C_APP_CLIENT_ID}}#&scope=https%3A%2F%2F#{{B2C_TENANT_NAME}}#.onmicrosoft.com%2F#{{B2C_APP_CLIENT_ID}}#%2Fapi%2Fauth%20openid%20profile%20offline_access&client-request-id=ec49fcbe-aaea-401d-a371-98b47be65f36&response_mode=fragment&response_type=code&x-client-SKU=msal.js.browser&x-client-VER=2.18.0&x-client-OS=&x-client-CPU=&client_info=1&code_challenge=K3lOmZgIP0JStYofNFTNLuPujpAEU--TueeuLBQrxKY&code_challenge_method=S256&nonce=a0a99450-e135-4b78-968e-921dd2c32ce7&state=eyJpZCI6ImNmZmYzMGE0LTgwYjUtNDFlNC04NTVhLWY5MzE3MThhN2ZmZiIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3`,
    protectedResourceMap: [
      '#{{apiServer.cossa}}#',  // CPS.Cossa.Service
      '#{{apiServer.appSecService}}#',  // CPS.RxAdmin.Service
      '#{{apiServer.msGraph}}#',
      '#{{apiServer.appMsgService}}#',
      '#{{apiServer.rxContentHub}}#',
      '#{{apiServer.purchasing}}#'
    ]

  },
  msalConfig: {
    cacheLocation: "localStorage"
  },
  appConfig: {
    env: {
      name: "prod",
    },
    logging: {
      console: true,
      appInsights: true,
    },
    apiServer: {
      appSecService: "#{{apiServer.appSecService}}#",
      cossa: "#{{apiServer.cossa}}#",
      msGraph: "#{{apiServer.msGraph}}#",
      appSecServiceAppCode: "AppSecMan",
      appMessaging: '#{{apiServer.appMsgService}}#',
      loginUrl: "#{{CPS_MSAL_CONFIG_REDIRECT_URI}}#",
      rxContentHub: '#{{apiServer.rxContentHub}}#',
      purchasing: '#{{apiServer.purchasing}}#',
      appCode: 'AppSecMgmt'
    },
    biReports: {
      environmentName: '#{{biReports.environmentName}}#'
    },
    messageServiceLink: {
      appCode:'AppSecMgmt',
      remindMeHours: '#{{messageServiceLink.remindMeHours}}#'
    },
    navigationUrl: {
      questionIcon: "#{{navigationUrl.questionIcon}}#",
    },
    snackBar: {
      successDuration: 2000,
      errorDuration: 2000,
      warningDuration: 2000,
    },
    searchIcon: {
      rxContentHubSearchUrl: "#{{searchIcon.rxContentHubSearchUrl}}#",
    },
    help: {
      appCode:'#{{help.appCode}}#',
      appName:'#{{help.appName}}#',
      changePasswordUrl:'#{{help.changePasswordUrl}}#',
      contactApplicationSupportUrl:'#{{help.contactApplicationSupportUrl}}#'
    },
    appMessage: {
      endDatePeriod: 30,
    },
  },
  newRelic:{
    accountID:"#{{newRelic.accountID}}#",
    trustKey:"#{{newRelic.trustKey}}#",
    agentID:"#{{newRelic.agentID}}#",
    licenseKey:"#{{newRelic.licenseKey}}#",
    applicationID:"#{{newRelic.applicationID}}#"
  },
  forgotPasswordLink: `https://#{{B2C_TENANT_NAME}}#.b2clogin.com/#{{B2C_TENANT_ID}}#/B2C_1A_UA_PASSWORDRESET/oauth2/v2.0/authorize?client_id=#{{B2C_APP_CLIENT_ID}}#&scope=https%3A%2F%2F#{{B2C_TENANT_NAME}}#.onmicrosoft.com%2F#{{B2C_APP_CLIENT_ID}}#%2Fapi%2Fauth%20openid%20profile%20offline_access&client-request-id=ec49fcbe-aaea-401d-a371-98b47be65f36&response_mode=fragment&response_type=code&x-client-SKU=msal.js.browser&x-client-VER=2.18.0&x-client-OS=&x-client-CPU=&client_info=1&code_challenge=K3lOmZgIP0JStYofNFTNLuPujpAEU--TueeuLBQrxKY&code_challenge_method=S256&nonce=a0a99450-e135-4b78-968e-921dd2c32ce7&state=eyJpZCI6ImNmZmYzMGE0LTgwYjUtNDFlNC04NTVhLWY5MzE3MThhN2ZmZiIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3`,
  menuPackage: {
    termsAndConditions: '#{{Menu.Terms_And_Conditions}}#',
    marketingPageLink: '#{{Menu.Marketing_Page_Link}}#',
    footerTermsAndConditons: '#{{Menu.Footer.Terms_And_Conditons}}#',
    headerDevWalkme: '#{{Menu.Header_Dev_Walkme}}#',
    headerWalkme: '#{{Menu.Header_Walkme}}#',
    headerBiosimilarsLink: '#{{Menu.Header_Biosimilars_Link}}#',
    headerAssessmentsLink: '#{{Menu.Header_Assessments_Link}}#',
    headerInitiativesLink: '#{{Menu.Header_Initiatives_Link}}#',
    headerDocumentsLink: '#{{Menu.Header_Documents_Link}}#',
    headreMetricsLink: '#{{Menu.Headre_Metrics_Link}}#',
  }
};
